<template>
  <div class="container">
    <navbar></navbar>
    <!-- Loading -->
    <div class="cover" v-if="loadFlag == true">
      <van-loading class="loading" type="spinner" size="48px" />
      <div class="load-text">加载中...</div>
    </div>
    <!-- 题数，提交按钮 -->
    <div class="ad-header">
      <div class="ad-type" v-if="question.topicType == 0">【单选】</div>
      <div class="ad-type" v-else>【多选】</div>
      <div class="ad-number">
        <span>{{ now + 1 }}</span><span>/{{ resData.length }}</span>
      </div>
      <div class="ad-commit-btn" v-if="from == 'scan'" @click="submit()">
        提交
      </div>
    </div>
    <!-- 题目 -->
    <div class="ad-subject">
      {{ question.topicText }}
    </div>
    <!-- 选择题 -->
    <div class="ad-question">
      <!-- 每个选项 -->
      <!-- 单选 -->
      <div v-if="question.topicType == 0">
        <div class="ad-question-each" v-for="(item, index) in question.options" :key="index" @click="singleChoice(index)">
          <div>
            <div :class="
                item.checked == true
                  ? 'ad-each-option-choose'
                  : 'ad-each-option'
              ">
              {{ item.option }}
            </div>
          </div>
          <div class="ad-each-content">{{ item.text }}</div>
        </div>
      </div>
      <!-- 多选 -->
      <div v-else>
        <div class="ad-question-each" v-for="(item, index) in question.options" :key="index" @click="multipleChoice(index)">
          <div :class="
              item.checked == true ? 'ad-each-option-choose' : 'ad-each-option'
            ">
            {{ item.option }}
          </div>
          <div class="ad-each-content">{{ item.text }}</div>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <div class="ad-footer">
      <div class="ad-prev" @click="prev">上一题</div>
      <div class="ad-next" @click="next">下一题</div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import navbar from '@/components/navBar.vue'
import { searchTopicList, addTopic, searchResultExamList } from '@/api/api.js'
import { Toast, Dialog } from 'vant'

export default {
  name: 'answerDetail',
  components: {
    navbar
  },
  data() {
    return {
      resData: [],
      now: 0,
      question: [],
      from: '',
      loadFlag: false,
      userExamId: ''
    }
  },
  mounted() {
    this.from = this.$route.query.from
    this.userExamId = this.$route.query.userExamId
    if (this.userExamId == null || this.userExamId == '') {
      Toast('未获取到考试人员信息')
      this.from = 'home'
    }
    if (this.from == 'scan') {
      // 查询考试题目列表
      this.searchTest()
    } else {
      // 查询考试已答题目列表
      this.answeredList()
    }
  },
  methods: {
    // 查询考试题目列表
    searchTest() {
      searchTopicList({
        examId: this.$route.query.examId,
        userId: this.$route.query.userId
      }).then((res) => {
        if (res.data.code == 0) {
          let resData = res.data.data
          //   将字符串格式的选项转成json格式
          for (let i = 0; i < resData.length; i++) {
            resData[i].options = JSON.parse(resData[i].options)
            // 为每组options添加checked
            for (let j = 0; j < resData[i].options.length; j++) {
              resData[i].options[j].checked = false
            }
          }

          this.resData = resData
          this.question = this.resData[0]
          console.log(this.question)
        } else {
          Toast(res.data.msg)
        }
      })
    },
    // 查询考试已答题目列表
    answeredList() {
      searchResultExamList({
        userExamId: this.userExamId
      }).then((res) => {
        if (res.data.code == 0) {
          let resData = res.data.data
          //   将字符串格式的选项转成json格式
          for (let i = 0; i < resData.length; i++) {
            resData[i].options = JSON.parse(resData[i].options)
          }

          this.resData = resData
          this.question = this.resData[0]
          console.log(this.question)
        } else {
          Toast(res.data.msg)
        }
      })
    },
    //下一题
    next() {
      this.now += 1
      if (this.now > this.resData.length - 1) {
        Toast('没有下一题了')
        this.now -= 1
        return
      }
      this.question = this.resData[this.now]
    },
    // 上一题
    prev() {
      this.now -= 1
      if (this.now == -1) {
        Toast('没有上一题了')
        this.now = 0
        return
      }
      this.question = this.resData[this.now]
    },
    //单选的选项被选中时
    singleChoice(index) {
      if (this.from == 'home') {
        return
      }
      for (let i = 0; i < this.question.options.length; i++) {
        this.question.options[i].checked = false
      }
      this.question.options[index].checked = true
      this.resData[this.now] = this.question
      console.log(this.resData)
    },
    // 多选的选项被选中时
    multipleChoice(index) {
      if (this.from == 'home') {
        return
      }
      this.question.options[index].checked = !this.question.options[index].checked
      this.resData[this.now] = this.question
    },
    // 提交
    submit() {
      Dialog.confirm({
        message: '您确定要提交此次答题吗'
      })
        .then(() => {
          this.loadFlag = true
          let list = []
          for (let i = 0; i < this.resData.length; i++) {
            let obj = new Object()
            obj.options = JSON.stringify(this.resData[i].options)
            obj.topicAnswer = this.resData[i].answer
            obj.topicId = this.resData[i].id
            obj.topicText = this.resData[i].topicText
            obj.topicType = this.resData[i].topicType
            //-------------------------------------------------------------------------------------------------
            // topicType 0：单选，1：多选
            if (this.resData[i].topicType == 0) {
              obj.userAnswer = ''
              for (let a = 0; a < this.resData[i].options.length; a++) {
                if (this.resData[i].options[a].checked == true) {
                  obj.userAnswer = this.resData[i].options[a].option
                }
              }
            } else if (this.resData[i].topicType == 1) {
              obj.userAnswer = ''
              let demoList = []
              for (let b = 0; b < this.resData[i].options.length; b++) {
                if (this.resData[i].options[b].checked == true) {
                  demoList.push(this.resData[i].options[b].option.toString())
                }
              }
              // 颠倒数组的元素顺序
              demoList.reverse()
              if (demoList.length != 0) {
                obj.userAnswer = JSON.stringify(demoList)
              }
            }
            //-------------------------------------------------------------------------------------------------
            obj.userExamId = this.userExamId
            list.push(obj)
          }
          addTopic(list).then((res) => {
            if (res.data.code == 0) {
              Toast('提交成功！')
              this.loadFlag = false
              setTimeout(this.$router.push({ path: '/' }), 1000)
            } else {
              this.loadFlag = false
              Toast(res.data.msg)
            }
          }).catch(err => {
            this.loadFlag = false;
          });
        })
        .catch(() => {
          // on cancel
        })
    }
  }
}
</script>
<style>
.ad-header {
  box-sizing: border-box;
  width: 100%;
  height: 60px;
  padding: 0 30px;
  margin-top: 40px;
  position: relative;
}

.ad-type,
.ad-number {
  font-weight: bold;
  font-size: 35px;
  position: absolute;
  top: 50%;
  line-height: 60px;
  transform: translateY(-50%);
}

.ad-type {
  color: #e03838;
}

.ad-number {
  left: 180px;
}

.ad-number :nth-child(1) {
  color: #e03838;
}
.ad-number :nth-child(2) {
  color: #d9d9d9;
}

.ad-commit-btn {
  position: absolute;
  width: 140px;
  height: 60px;
  line-height: 60px;
  background: #cca766;
  color: #fff;
  text-align: center;
  font-size: 28px;
  font-family: PingFang SC;
  font-weight: 400;
  right: 30px;
  border-radius: 6px;
}

.ad-subject {
  color: #052140;
  font-size: 32px;
  font-family: PingFang SC;
  font-weight: bold;
  padding: 0 35px;
  margin-top: 40px;
}

/* .ad-question {
  padding-left: 30px;
  border:2px solid black;
}

.ad-question-each {
  position: relative;
  margin-top: 60px;
  width: 100%;
  height: 40px;
}

.ad-each-option {
  font-size: 30px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border: solid #cfcfcf 1px;
  color: #cfcfcf;
  border-radius: 50%;
  float: left;
}

.ad-each-option-choose {
  font-size: 30px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border: solid #cfcfcf 1px;
  color: #ffffff;
  background: #e03838;
  border-radius: 50%;
  float: left;
}

.ad-each-content {
  float: left;
  margin-left: 20px;
  font-size: 28px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 40px;
  color: #333333;
  width: 90%;
} */
.ad-question {
  padding-left: 30px;
  display: flex;
  flex-direction: column;
  margin-bottom: 120px;
}

.ad-question-each {
  margin-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
}

.ad-each-option,
.ad-each-option-choose {
  font-size: 30px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border: solid #cfcfcf 1px;
  border-radius: 50%;
}

.ad-each-option {
  color: #cfcfcf;
}

.ad-each-option-choose {
  color: #ffffff;
  background: #e03838;
}

.ad-each-content {
  font-size: 28px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 40px;
  color: #333333;
  margin: 0 20px;
  width: 90%;
}

.ad-footer {
  position: fixed;
  border-top: 2px solid #e9e9e9;
  background: #e9e9e9;
  bottom: 0;
  width: 100%;
  height: 120px;
}

.ad-prev,
.ad-next {
  position: absolute;
  width: 340px;
  height: 60px;
  line-height: 60px;
  background: #cca766;
  color: #fff;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 5px;
  font-size: 30px;
}

.ad-prev {
  left: 30px;
}

.ad-next {
  right: 30px;
}

.cover {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.5;
  z-index: 999;
}

.loading,
.load-text {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.load-text {
  top: 50%;
  color: #fff;
  font-size: 35px;
}
</style>
